import React from 'react';
import { useHistory } from 'react-router';

import PeaknStatsCard from '../PeakAndStatsCard';
import StatsSection from '../StatsSection/StatsSection';
import CardFooter from '../CardFooter/CardFooter';

import BannerImage from '../../../Resources/images/Points.png';
import taskBanner from '../../../Resources/images/taskbanner.svg'

function StatsSlide(props) {
  const { data } = props;
  const history = useHistory()

  const banner = {
    text: 'Complete tasks to earn points',
    image: taskBanner,
    gridTemplateColumns: "2fr 1fr",
  };

  const footer = {
    text: null,
    button: {
      text: 'See all tasks',
      bgColor: 'rgba(255, 255, 255, 1)',
    },
    deepLink: data?.deeplink,
    link : () => history.push('/details/tasks')
  };

  return (
    <div
      className="Slide"
      style={{
        backgroundColor: '#1E1A3A',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.16)',
        borderRadius: '8px',
        margin: '2px',
      }}
    >
      <PeaknStatsCard data={banner} />
      <StatsSection data={data?.stats} />
      <CardFooter data={footer} />
    </div>
  );
}

export default StatsSlide;
