import React,{useState} from 'react';
import Stories from 'react-insta-stories'
import './Leaderboard.scss';

import rocket from '../../Resources/images/rocket.svg'
import blob from '../../Resources/images/blob.svg'
import dashedlines from '../../Resources/images/dashedlines.svg'
import Ranking from './Ranking';

function Leaderboard(props) {
  const { data, swipeActions,setShowStories } = props;
  // const [showStories,setShowStories] = useState(false)

  return (
    <div className="Leaderboard fadeIn" {...swipeActions}>
      <div className="Leaderboard__Body">
        
        <div className='Leaderboard__Storycard'>
          <div className='Leaderboard__Storycard__Left'>
            <div className='Leaderboard__Storycard__Left--text'>
              Marketing genius of the day
            </div>
            <div className='Leaderboard__Storycard__Left--button' onClick={()=>setShowStories(true)}>
              See Now
            </div>
          </div>
          <div className='Leaderboard__Storycard__Right'>
            <img src={rocket} alt="rocket" height="80px" style={{borderRadius:"50px"}}/>
          </div>
          <img className="Leaderboard__Storycard__Blob" src={blob} alt="blob" height="100%"/>
          <img className="Leaderboard__Storycard__Lines" src={dashedlines} alt="blob" height="100%"/>
        </div>

        <div className="Leaderboard__Heading">Ranking</div>
        <div className="Leaderboard__Subheading">
          Know where you stand among other institutes
        </div>

        <Ranking data={data?.teamRankings} />

        {data?.awardsImage?.imgUrl ? (
          <img
            className="Leaderboard__Body--Prize"
            src={data?.awardsImage?.imgUrl}
            width="100%"
            alt=""
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export default Leaderboard;
