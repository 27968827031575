import React, { forwardRef } from 'react';

const Navbar = forwardRef((props, ref) => {
  const { data, activeTab, updateActiveTab, scrollNav } = props;

  return (
    <div className="NavBar" ref={ref}>
      {data?.map((item, i) => {
        const isActive = item?.key === activeTab?.key;
        return (
          <span
            id={item?.key}
            className="NavBar__MenuItem"
            key={item?.key}
            style={{
              color: isActive ? "rgba(0, 0, 0, 0.87)" : "rgba(0, 0, 0, 0.6)",
              borderBottom: isActive ? '4px solid #212121' : 'none',
            }}
            onClick={() => {
              updateActiveTab(item);
            }}
          >
            {item?.name}
          </span>
        );
      })}
    </div>
  );
});

export default Navbar;
