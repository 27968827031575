import React from 'react';
import Coin from '../../../Resources/images/Coin.svg';
import share from '../../../Resources/images/share.svg'
import coupons from '../../../Resources/images/coupon.svg'
import { directDeepLink, SOURCE_HOME } from '../../../utils';

function StatsCard(props) {
  const { data,index } = props;

  const getIcon = () => {
    switch(index) {
      case 0 : return share;
      case 1: return coupons;
      default: return null;
    }
  }

  return data ? (
    <div className="StatsCard">
      <div className="StatsCard__Left">
        <div className="StatsCard__Left__Logo">
          <img src={getIcon()} alt="" />
        </div>
        <div className="StatsCard__Left__Body">
          <div className="StatsCard__Left__Body--Label">{data?.text}</div>
          <div className="StatsCard__Left__Body--Stat">{data?.subText}</div>
          <div
            className="StatsCard__Left__Body--Button"
            onClick={directDeepLink(data?.button?.deeplink, SOURCE_HOME)}
          >
            {data?.button?.text}
          </div>
        </div>
      </div>

      <div className="StatsCard__Points">
        <img src={Coin} alt="" />
        <span>{data?.points}</span>
      </div>
    </div>
  ) : (
    ''
  );
}

export default StatsCard;
