import React from 'react';
import './PeakAndStatsCard.scss';

const PeaknStatsCard = (props) => {
  const { data } = props;
  return (
    <div className="PeaknStatsCard">
      <div className="PeaknStatsCard_left">{data.text}</div>
      <div className="PeaknStatsCard_right">
        <img src={data.image} alt="gift" height="100%" loading="eager" style={{ margin: data.imgMargin || "unset" }} />
      </div>
    </div>
  );
};

export default PeaknStatsCard;
