import React from 'react';
import { useHistory } from 'react-router';
// import PointsCard from '../PointsCard';
// import LightsImage from "../../../Resources/images/Winter.png";
import homeBanner from '../../../Resources/images/sapno-home.svg'
import PeaknStatsCard from '../PeakAndStatsCard';
import RevenueCard from '../RevenueCard';
import CardFooter from '../CardFooter/CardFooter';

function HomeSlide(props) {
  const { data } = props;
  const { nextMilestone,revenue } = data;
  const history = useHistory()

  const banner = {
    text: "Sapno Ki Udaan",
    image: homeBanner,
    gridTemplateColumns: "9fr 11fr",
  };


  const footer = {
    text: parseInt(nextMilestone?.nextMilestone < 500) 
    ? "Congratulations! You've completed all milestones!" 
    : `Next Milestone : Promote & share to get ${nextMilestone?.nextMilestone} new students on your app`,
    button: {
      text: "View all milestones & rewards",
      bgColor: "rgba(255, 255, 255, 1)",
    },
    deepLink: data?.deeplink,
    link : () => history.push('/details/milestones')
  };
  

  // const points = [data?.rankCard, data?.pointsCard];

  return (
    <div
      className="Slide"
      style={{
        backgroundColor: '#1E1A3A',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.16)',
        borderRadius: '8px',
        margin: '2px',
      }}
    >
      <PeaknStatsCard data={banner} />
      <RevenueCard data={revenue} milestoneData={nextMilestone}/>
      {/* <PointsCard data={points} /> */}
      <CardFooter data={footer} />
    </div>
  );
}

export default HomeSlide;
