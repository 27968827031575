import React from 'react';
import './Ranking.scss';

function Ranking(props) {
  const { data } = props;
  return (
    <div className="Ranking">
      <div className="Ranking__Headers">
        <div className="Ranking--rank">Rank</div>
        <div className="Ranking--institute">Institute</div>
        <div className="Ranking--points">Points</div>
      </div>

      {data?.map((data) => {
        return (
          <div
            key={data?.rank}
            className="Ranking__Block"
            style={{
              backgroundColor:
                data && data.myOrg ? 'rgba(63, 81, 181, 0.16)' : '#ffffff',
            }}
          >
            <div className="Ranking--rank">
              {data?.rankImg ? <img src={data?.rankImg} /> : data?.rank}
            </div>
            <div className="Ranking--institute">{data && data.orgName}</div>
            <div className="Ranking--points">{data && data.points}</div>
          </div>
        );
      })}
    </div>
  );
}

export default Ranking;
