import React from 'react';
import ToolCard from './ToolCard';
import './tools.scss';

function Tools(props) {
  const { data, swipeActions } = props;

  return (
    <div className="Tools fadeIn" {...swipeActions}>
      <div className="Tools__Top">
        <div className="Tools__Top--Heading">Tools to get ahead</div>
        <div className="Tools__Top--SubHeading">
          Use these tools to improve your store performance and earn upto 3x
          every month!
        </div>
      </div>
      <div className="Tools__Body">
        {data?.map((tool, i) => {
          return <ToolCard data={tool} key={i} isTab idx={i}/>;
        })}
      </div>
    </div>
  );
}

export default Tools;
