import React from 'react';

import ProgressBar from '../UI/ProgressBar/ProgressBar';

import Coin from '../../Resources/images/Coin.svg';
import { directDeepLink, SOURCE_WEBVIEW } from '../../utils';

function TaskCard(props) {
  const { data } = props;

  return (
    <div
      className="TaskCard fadeIn"
      style={{ backgroundColor: data?.bgColor, border: data?.border }}
    >
      <div className="TaskCard__Stats">
        <div className="TaskCard__Stats__Top">
          <div className="TaskCard__Stats__Top__Label">
            <img src={data?.icon} alt="ICON"/>
            <div>
              <span className="TaskCard__Stats__Top__Label--Info">
                {data?.task}
              </span>
              {data?.percentage ? null : <div className="TaskCard__Stats--NotCompleted">{data?.completedText}</div>}
            </div>
          </div>
          <div className="TaskCard__Stats__Top__Points">
            <img src={Coin} alt="" />
            <span className="TaskCard__Stats__Top__Points--Count">
              {data?.points}
            </span>
          </div>
        </div>
        {data?.percentage ? <><ProgressBar
          width={data?.percentage}
          bgColor={data?.progressBarColor}
        />
          <div className="TaskCard__Stats--Completed">{data?.completedText}</div></> : null}
      </div>

      <div className="TaskCard__Action">
        <div className="TaskCard__Action--Info">{data?.taskInfo}</div>
        <div className="TaskCard__Action--subInfo">{data?.subText}</div>
        { data.button && (
          data?.button?.deeplink ? (
            <div
              className="TaskCard__Action--Button"
              onClick={directDeepLink(data?.button?.deeplink, SOURCE_WEBVIEW)}
            >
              {data?.button?.text}
            </div>
          ) : (
            <div
              className="TaskCard__Action--Button"
              onClick={()=> data.button.link()}
            >
              {data?.button?.text}
            </div>
          )
        )}
        
      </div>
    </div>
  );
}

export default TaskCard;
