import React from 'react';
import './RevenueCard.scss';

import ProgressBar from '../../UI/ProgressBar/ProgressBar';

import Coin from '../../../Resources/images/Coin.svg';
import appDownload from '../../../Resources/images/appdownload.svg'

function RevenueCard(props) {
  const { data, changeRoute, handleClick,milestoneData } = props;

  return (
    <div className="RevenueCard" onClick={handleClick}>
      <div
        className="RevenueCard__Top"
        // style={{ backgroundImage: `url(${data?.cardBgImage})` }}
      >
        <div style={{display:"flex",alignItems:"center",marginBottom:"80px"}}>
          <img src={appDownload} alt="download" height="45px" style={{marginRight:"20px"}}/>
          <div>
            <div className="RevenueCard__Top--Label">{data?.title}</div>
            <div className="RevenueCard__Head">
              <div className="RevenueCard__Head--Logo">
                <div className="RevenueCard__Head--Logo--Text">{data?.value}</div>
              </div>
            </div>
          </div> 
        </div>
        

        <ProgressBar
          width={milestoneData?.percentage}
          // width={"50"}
          // bgColor={data?.progressBarColor}
          // bgColor={"green"}
        />

        {/* <div className="RevenueCard__New_Footer">
          <div className="RevenueCard__New_Footer__left">
            {data?.completedPercent}
          </div>
          <div className="RevenueCard__New_Footer__right">
            Goal: {data?.goal}
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default RevenueCard;
