import React from "react";
import "./NewRewards.scss";

import crossIcon from "../../Resources/images/x.png"
import useBodyOverflowHidden from "./useBodyOverflowHidden";

import { directDeepLink, SOURCE_HOME, SOURCE_WEBVIEW } from "../../utils";

const NewRewards = ({ data, swipeActions, setNav }) => {

    const { rewards, studentReward } = data;

    const [showBottomDrawer, setShowBottomDrawer] = React.useState(false);

    return (
        <div className="newRewards fadeIn" {...swipeActions}>
            {rewards?.map((reward, index) => <React.Fragment key={`reward${index}`}>
                {reward?.head && <NewRewardsHeader heading={reward?.head?.title} subHeading={reward?.head?.subTitle} />}
                {reward?.body?.cards?.map((card, index) => <React.Fragment key={`card${index}`}>
                    <img className="newRewards__img" src={card.image} alt="reward" />
                    {card?.icon && <NewRewardsIconHeader icon={card.icon} heading={card.heading} subHeading={card.text} />}
                </React.Fragment>)}
                <div className="newRewards__hr"></div>
            </React.Fragment>)}
            <ShareStudentImageCard onClick={() => setShowBottomDrawer(true)} bgImg={studentReward?.icon} text={studentReward?.text} icon={studentReward?.nextIcon} />
            {showBottomDrawer && <ShareBottomDrawer setNav={setNav} bottomDrawer={studentReward?.bottomDrawer} button={studentReward?.button} closeDrawer={() => setShowBottomDrawer(false)} />}
        </div >
    )
}

const ShareStudentImageCard = ({ onClick, bgImg, text, icon }) => {
    return (
        <div className="shareStudentImageCard" style={{
            backgroundImage: `url(${bgImg})`,
            backgroundRepeat: "no-repeat",
            backgroundPositionX: "left",
            backgroundPositionY: "bottom",
        }} onClick={onClick}>
            <div className="shareStudentImageCard__inner">
                <p>{text}</p>
                <img src={icon} />
            </div>
        </div >
    )
}

const ShareBottomDrawer = ({ setNav, bottomDrawer, button, closeDrawer }) => {

    useBodyOverflowHidden();

    const bottomDrawerRef = React.useRef();

    const outsideClickEvent = (e) => {

        if (e.target.className === "bottomDrawerContainer") {
            handleDrawerClose();
        }
    }

    React.useEffect(() => {
        setNav(false);
        document.addEventListener("click", outsideClickEvent);
        bottomDrawerRef.current.style.bottom = `-${bottomDrawerRef.current.offsetHeight}px`;
        bottomDrawerRef.current.style.bottom = "0";
        return () => {
            document.removeEventListener("click", outsideClickEvent);
        }
    }, [])

    const handleDrawerClose = () => {
        bottomDrawerRef.current.style.bottom = `-${bottomDrawerRef.current.offsetHeight}px`;
        setTimeout(() => {
            closeDrawer();
            setNav(true);
        }, 500);
    }

    return (
        <div className="bottomDrawerContainer">
            <div className="bottomDrawer" ref={bottomDrawerRef}>
                <div className="bottomDrawer__header">
                    <span>Student Rewards</span>
                    <img src={crossIcon} alt="crossIcon" onClick={handleDrawerClose} />
                </div>
                <div className="bottomDrawer__content">
                    <p>Your students stand a chance to win exciting rewards on content market course purchase from your store during the sale!</p>
                    {bottomDrawer?.cards?.map(card => <BottomDrawerCard logo={card?.logo} bgColor={card?.color} text={card?.text} />)}
                </div>
                <div className="bottomDrawer__footer">
                    <button onClick={directDeepLink(button?.deepLink, SOURCE_WEBVIEW)} style={{ background: button?.bgColor }}>{button?.text}</button>
                </div>
            </div>
        </div>
    )
}

const BottomDrawerCard = ({ bgColor, logo, text }) => {
    return (
        <div className="bottomDrawerCard" style={{ background: bgColor }}>
            <div className="bottomDrawerCard__img">
                <img src={logo} />
            </div>
            <div className="bottomDrawerCard__text">{text}</div>
        </div>
    )
}

const NewRewardsHeader = ({ heading, subHeading }) => {
    return (
        <div className="newRewardsHeader">
            <h1>{heading}</h1>
            <p>{subHeading}</p>
        </div>
    )
}

const NewRewardsIconHeader = ({ icon, heading, subHeading, image }) => {
    return (
        <div className="newRewardsIconHeader">
            <img src={icon} alt="trophy" />
            <div>
                <h1>{heading}</h1>
                <p>{subHeading}</p>
            </div>
        </div>
    )
}

export default NewRewards;