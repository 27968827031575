import React from 'react';

import ToolsArrow from '../../Resources/images/ToolsArrow.svg';
import { directDeepLink, SOURCE_HOME, SOURCE_WEBVIEW } from '../../utils';
import Image from '../../Resources/images/youtubeBG.png';

function ToolCard(props) {
  const { data, isTab, idx } = props;
  return (
    <div
      className="ToolCard"
      style={{
        background: idx === 0 ? `url(${Image})` : data?.bgColor,
        gridColumn: idx === 0 ? '1/-1' : '',
      }}
      onClick={directDeepLink(
        data?.deeplink,
        isTab ? SOURCE_WEBVIEW : SOURCE_HOME
      )}
    >
      {idx !== 0 && (
        <img className="ToolCard--Icon" src={data?.icon} alt="ICON" />
      )}
      <div className="ToolCard__Label">{data?.text}</div>
      {idx === 0 && (
        <div className="ToolCard--Text">
          Start your YouTube journey & grow like never before!
        </div>
      )}
      <img className="ToolCard--Arrow" src={ToolsArrow} alt="" />
    </div>
  );
}

export default ToolCard;
