import React from 'react';
import RightArrow from '../../../Resources/images/rightgreen.svg';

const LPText = ({ showHeading }) => {
  return (
    <div className="Rewards__LP__text">
      {showHeading && (
        <div className="Rewards__LP__text__heading">Rewards up for grabs</div>
      )}
      <div className="Rewards__LP__text__points">
        <div className="Rewards__LP__text__points__point">
          <div className="Rewards__LP__text__points__point__image">
            <img src={RightArrow} />
          </div>
          <div className="Rewards__LP__text__points__point__text">
            You can claim rewards after completing 50% of your revenue goal
          </div>
        </div>
        <div className="Rewards__LP__text__points__point">
          <div className="Rewards__LP__text__points__point__image">
            <img src={RightArrow} />
          </div>
          <div className="Rewards__LP__text__points__point__text">
            Points you use to claim rewards will be deducted from your total
            points and will affect your rank on the leaderboard
          </div>
        </div>
        {/* <div className="Rewards__LP__text__points__point">
          <div className="Rewards__LP__text__points__point__image">
            <img src={RightArrow} />
          </div>
          <div className="Rewards__LP__text__points__point__text">
            Claim your reward before 8th September 2021, 11:59 PM.
          </div>
        </div> */}
      </div>
    </div>
  );
};
LPText.defaultProps = {
  showHeading: true,
};
export default LPText;
