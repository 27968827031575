import React from 'react';
import first from '../../../Resources/images/200.svg'
import second from '../../../Resources/images/300.svg'
import third from '../../../Resources/images/400.svg'
import fourth from '../../../Resources/images/500.svg'
import line from '../../../Resources/images/line.svg'
import './progressBar.scss';

function ProgressBar(props) {
  const { width, bgColor } = props;
  return (
    <div className="ProgressBar">
      <span className="ProgressBar__Indicator" style={{ width: `${width}%` }}>
        <span
          className="ProgressBar__Indicator--Fill"
          style={{ backgroundColor: "green" }}
        ></span>
      </span>
      <img className="FirstMile" src={first} alt="first" height="30px"/>
      <img className="line1" src={line} alt="line" height="10px" />
      <img className="SecondMile" src={second} alt="second" height="30px"/>
      <img className="line2" src={line} alt="line" height="10px" />
      <img className="ThirdMile" src={third} alt="third" height="30px"/>
      <img className="line3" src={line} alt="line" height="10px" />
      <img className="FourthMile" src={fourth} alt="fourth" height="30px"/>
      <img className="line4" src={line} alt="line" height="10px" />
      
    </div>
  );
}

export default ProgressBar;
