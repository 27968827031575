import React, { useEffect, useState } from 'react';
import './tasks.scss';

import TaskCard from './TaskCard';
import yt from '../../Resources/images/yt.svg'
import download from '../../Resources/images/download.svg'
import coupon from '../../Resources/images/coupon.svg'
import share from '../../Resources/images/share.svg'

function Tasks(props) {
  const { data, swipeActions,appLink } = props;

  const taskData = [
    {
      bgColor: "rgba(214, 85, 78, 0.08)",
      icon: download,
      border: "1px solid #071D32",
      task:"Get New App Downloads",
      points:"50 pts",
      taskInfo:"Earn 5 points for each new student who downloads your app",
      button: {
        text:"Share App Link",
        link: () => window.open(
          `https://wa.me/?text=${encodeURI(`Want unlimited access to free Classes and Courses? Download Now! : ${appLink}`)}`
        ),
        // deeplink: {
        //   screen: "UTIL_SHARE",
        //   paramOne: `${appLink}`,
        //   paramTwo: "",
        //   paramThree: ""
        // }
      }

    },
    {
      bgColor: "#ffffff",
      // border: "1px solid #071D32",
      task:"Create coupon codes to promote your courses",
      icon: coupon,
      points:"50 pts",
      taskInfo:"Earn 20 points for each coupon that you create",
      subText:"*You can earn max 40 points per day by creating coupons",
      button: {
        text: "Create Now",
        deeplink: {
            screen: "COUPON_CREATE",
            paramOne: "",
            paramTwo: "",
            paramThree: ""
        }
      }

    },
    {
      bgColor: "#ffffff",
      // border: "1px solid #071D32",
      task:"Create & share posters or videos to promote your app",
      icon: share,
      points:"50 pts",
      taskInfo:"Earn 10 points for each post (poster/video) that you share with students",
      subText:"*You can earn max 40 points per day. Updated points will reflect 1-2 days after you reach out to your CSM",
      button: {
        text:"Share Now",
        deeplink: {
            screen: "SCREEN_GROW",
            paramOne: "",
            paramThree: "",
            paramTwo: ""
        }
      }

    },
    {
      bgColor: "#ffffff",
      // border: "1px solid #071D32",
      task:"Promote your app via YouTube Videos",
      points:"60 pts",
      icon:yt,
      taskInfo:"Earn 30 points by promoting your own app & courses on YouTube.",
      subText:"*You can earn max 30 points per day & updated points reflect in 1-2 days",
      // button: {
      //   text:"Share App Link"
      // }

    },

  ]

  return (
    <div className="Tasks fadeIn" {...swipeActions}>
      <div className="Tasks__Top">
        <div className="Tasks__Top--Heading">How to earn points?</div>
        <div className="Tasks__Top--SubHeading">
          You can earn points by doing some simple tasks and teaching your
          students
        </div>
      </div>
      <div className="Tasks__Body">
        {taskData?.map((task, i) => {
          return <TaskCard data={task} key={i} />;
        })}
      </div>
    </div>
  );
}

export default Tasks;
