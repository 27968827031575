import React from 'react';
import ProgressBar from '../UI/ProgressBar/ProgressBar';
import { useHistory } from 'react-router';

import trophy from '../../Resources/images/TrophyThumb.svg';
import Coin from '../../Resources/images/Coin.svg';

function Drawer(props) {
  const { data } = props;
  const history = useHistory();

  return (
    <>
    {/* <div className='Nav'>
      <span style={{fontSize:"18px"}} onClick={()=> history.push('/')}>&larr;</span>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sapno Ki Udaan
    </div> */}
    <div
      className="Drawer"
      style={{ borderColor: data?.bgColor, backgroundColor: data?.bgColor }}
    >
      <div className="Drawer__Top"> 
        <div className="Drawer__Top__Profile">
          <img
            className="Drawer__Top__Profile--Img"
            src={data?.logo || "https://whitelabel-assets.s3.ap-south-1.amazonaws.com/production/campaign/Icons/defaultLogo.svg"}
            alt="LOGO"
          />
          <div>
            <span className="Drawer__Top__Profile--Name"> {data?.orgName}</span>
          </div>
          
        </div>
        <div className="Drawer__Top__Points">
          <span className="Drawer__Top__Points--Count">{data?.points} pts</span>
          <img src={Coin} alt="o" />
        </div>
      </div>
      <div className="Drawer__Body">
        <div className="Drawer__Body__Stats">
          <div className="Drawer__Body__Stats--Earning" style={{marginBottom:"55px"}}>{data?.appDownloads} New App Downloads</div>
          <ProgressBar
            bgColor={data?.progressBarColor}
            width={data?.percentage}
          />
          {/* <div className="Drawer__Body__Stats--Goal">Goal: {data?.goal}</div> */}
        </div>
      </div>
      {/*<div className="Drawer__Button" onClick={data?.hanleClick}>
            <img src={trophy} alt="" />
            <span className="Drawer__Button--Text">Your Rewards</span>
      </div>*/}
    </div>
    </>
  );
}

export default Drawer;
