import React from 'react';

import BannerImage from '../../../Resources/images/LandingScreen.png';
import Teacher from '../../../Resources/images/Teacher.svg';
import CardFooter from '../CardFooter/CardFooter';

function WelcomeSlide(props) {
  const { data } = props;

  const footer = {
    text: null,
    button: {
      text: 'Teachers’ Week Celebration',
      bgColor: '#453983',
    },
    deepLink: data?.deeplink,
  };

  return (
    <div
      className="Slide"
      style={{
        backgroundColor: '#B4B4ED',
        backgroundImage: `url(${BannerImage})`,
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.16)',
        borderRadius: '8px',
        margin: '2px',
      }}
    >
      <div className="Slide__Landing">
        <img src={Teacher} alt="" />
      </div>

      <CardFooter data={footer} />
    </div>
  );
}

export default WelcomeSlide;
