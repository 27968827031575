import React from 'react';
import './stats.scss';
import StatsCard from './StatsCard';

function StatsSection(props) {
  const { data } = props;
  const statsArray = [data?.appDownloads, data?.coupons];

  return (
    <div className="StatsSection">
      {statsArray.map((data, i) => {
        return <StatsCard data={data} key={i} index={i}/>;
      })}
    </div>
  );
}

export default StatsSection;
