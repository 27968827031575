import React from 'react';
import './PointsCard.scss';
import { useHistory } from 'react-router-dom';

const PointsCard = (props) => {
  const { data } = props;
  const history = useHistory();
  return (
    <div className="PointsCard">
      <div className="PointsCard__wrapper">
        {data &&
          data.map((data) => {
            return (
              <div className="PointsCard__wrapper__points" key={data?.title}>
                <div className="PointsCard__wrapper__points__text">
                  {data && data.text}
                </div>
                <div>
                  <img src={data && data.icon} alt="rank" loading="lazy" />
                  <div className="PointsCard__wrapper__points__digit">
                    {data && data.title}
                  </div>
                  <br />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default PointsCard;
