import React from 'react';
import MilestoneCard from './MilestoneCard';

function Checkpoints({ data }) {
  return (
    <div className="MilestoneCheckpoint">
      <div className="MilestoneCheckpoint__Top">
        <div className="MilestoneCheckpoint__Top--Heading">
          {data && data.heading}
        </div>
        <div className="MilestoneCheckpoint__Top--SubHeading">
          {data && data.subHeading}
        </div>
      </div>
      <div className="Milestone__Body">
        {data?.mileStoneData?.map((award) => (
          <MilestoneAward
            key={award.amount}
            barColor={award.barColor}
            dotColor={award.dotColor}
            icon={award.icon}
            amount={award.heading}
            amountColor={award.textColor}
            emblem={award.emblem}
            awardImg={award.imgUrl}
            isComplete={award.isComplete}
            isWinning={award.isWinning}
          />
        ))}
      </div>

      {/*<div className="MilestoneCheckpoint__List">
        {data &&
          data.mileStoneData &&
          data.mileStoneData.map((data, i) => {
            return <MilestoneCard key={i} index={i} data={data} />;
          })}
        </div>*/}

    </div>
  );
}

const MilestoneAward = ({ barColor, dotColor, icon, amount, amountColor, emblem, awardImg, isComplete, isWinning }) => {
  return (
    <div className="milestoneAward">
      <div className="milestoneAward__progress" style={{ background: barColor }}>
        {icon ? <img src={icon} alt="award-icon" /> : <div className="milestoneAward__progress__dot" style={{
          background: dotColor
        }}></div>}
      </div>
      <div className="milestoneAward__reward">
        <div className="milestoneAward__reward__score">
          <p className="milestoneAward__reward__score__p1" style={{ color: amountColor }}>{amount} Downloads</p>
          {emblem.text && <p className="milestoneAward__reward__score__p2" style={{
            background: emblem?.bgColor,
            color: emblem?.color,
            fontSize: isWinning ? "10px" : "16px"
          }}>{emblem?.text}</p>}
        </div>
        <div className="milestoneAward__reward__image milestoneAward__reward__image">
          <img src={awardImg} alt="rewardImg" className={isWinning ? "isWinning" : null} />
          {isComplete && !isWinning ? <div className="milestoneAward__reward__image__overlay"></div> : null}
        </div>
      </div>
    </div>)
}

export default Checkpoints;