import React from 'react';

import PeaknStatsCard from '../PeakAndStatsCard';

import BannerImage from '../../../Resources/images/rewards.png';
import CardFooter from '../CardFooter/CardFooter';

function RewardSlide(props) {
  const { data } = props;

  const banner = {
    text: 'Win big in Winter Season',
    image: BannerImage,
    gridTemplateColumns: "2fr 1fr",
  };

  const footer = {
    text: 'Rewards worth ₹ 4 Lakhs to be won',
    button: {
      text: 'See all Prizes',
      bgColor: 'rgba(255, 255, 255, 1)',
    },
    deepLink: data?.deeplink,
  };

  return (
    <div
      className="Slide"
      style={{
        backgroundColor: '#4F1D6B',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.16)',
        borderRadius: '8px',
        margin: '2px',
      }}
    >
      <PeaknStatsCard data={banner} />
      <div className="Slide__Rewards">
        <img
          src={data?.imgUrl}
          alt="Placeholder for icon which gives glimpse of rewards upfront"
        />
        <div className="Slide__Rewards__Text">
          <div className="Slide__Rewards__Text--Impact">
            {data?.rewardsText}
          </div>
          <div className="Slide__Rewards__Text--Count">{data?.BoldText}</div>
        </div>
      </div>
      <CardFooter data={footer} />
    </div>
  );
}

export default RewardSlide;
