import React, { useState, useEffect, useRef } from 'react';
import Stories from 'react-insta-stories'
import { useHistory } from 'react-router-dom';
import { GET, getQuery, makeApiCal, POST } from '../../utils';
import { useSwipeable } from 'react-swipeable';
import './layout.scss';

import cross from '../../Resources/images/crosswhite.png'
import Content from './Content';
import Drawer from './Drawer';
import Navbar from './Navbar';
import ContentLoader from '../Loader/ContentLoader';
import DrawerShimmer from '../Loader/DrawerShimmer';

function Layout(props) {

  async function postData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        // mode: 'cors', // no-cors, *cors, same-origin
        // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
        },
        // redirect: 'follow', // manual, *follow, error
        // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
}
  const history = useHistory();
  let navRef = useRef();
  const [apiData, setApiData] = useState();
  const [showStories,setShowStories] = useState(false);

  const [nav, setNav] = React.useState(true);

  const navBar = [
    {
      key: 'milestones',
      name: 'Milestones',

      drawerColor: 'rgba(28, 55, 103, 1)',
    },
    {
      key: 'leaderboards',
      name: 'Leaderboard',
      drawerColor: '#2B0D2C',
    },
    {
      key: 'tasks',
      name: 'Tasks',
      drawerColor: '#071D32',
    },
  ];

  const [activeTab, updateActiveTab] = useState();
  const [activeIndex, updateActiveIndex] = useState();

  const [loading, updateLoading] = useState(true);
  const [err, catchError] = useState(false);
  const [appLink,setAppLink] = useState()

  const getData = () => {
    const action = {
      method: GET,
      route: 'v2/course/campaign/sapnoKiUdaan',
      storeData: setApiData,
      catchError: catchError,
    };
    makeApiCal(action);
    getAppLink()
  };

  const getAppLink = () => {
    // alert('hi')
    const token = getQuery()['token'] || localStorage.getItem('campaignToken');
    postData("https://api.classplusapp.com/cams/graph-api", {
                query: "query ($token: String!) {withAuth(token: $token) { user  { org   {  name  code  } } }}",
                variables: { "token": token }
            }).then(response => {
                console.log(response, 'pppppppppppp')
                if (response && response.data) {
                  let orgDetails = response.data.withAuth.user.org
                  setAppLink('on-app.in/app/home?orgCode=' + orgDetails.code) 
                  // alert('on-app.in/app/home?orgCode=' + orgDetails.code)
                }
            });
  }


  useEffect(() => {
    getData();

    const tab = props?.match?.params?.tab;

    const scroller = new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log('S2');
        scrollNav(3);
        resolve();
      }, 1000);
    });

    scroller
      .then(() => {
        setTimeout(() => {
          scrollNav(-3);
          console.log('S3');
        }, 500);
      })
      .then(() => {
        if (tab === 'milestones2' || tab === 'rewards' || tab === 'tools') {
          setTimeout(() => {
            scrollNav(3);
            console.log('S4');
          }, 1000);
        }
      });
  }, []);

  useEffect(() => {
    const tab = props?.match?.params?.tab;
    if (apiData && appLink) {
      updateLoading(false);
      if (tab) {
        updateActiveTab(...navBar?.filter((item) => item.key === tab));
      }
    }
  }, [apiData, err,appLink]);

  useEffect(() => {
    if (activeTab) {
      history.push(`/details/${activeTab?.key}`);
      updateActiveIndex(
        navBar.findIndex((item) => item?.key === activeTab?.key)
      );
    }
  }, [activeTab]);

  const drawerData = {
    ...apiData?.campaignOrg,
    bgColor: activeTab?.drawerColor,
    hanleClick: () => history.push('/rewards'),
  };

  const changeTab = (value) => {
    if (activeIndex === navBar?.length - 1 && value > 0) {
      updateActiveTab(navBar[0]);
      scrollNav(-3);
    } else if (activeIndex === 0 && value < 0) {
      updateActiveTab(navBar[navBar?.length - 1]);
      scrollNav(3);
    } else {
      updateActiveTab(navBar[activeIndex + value]);
      scrollNav(value);
    }
  };

  const scrollNav = (value) => {
    const scrollWidth = window?.innerWidth * (Math.abs(value) * 0.34);
    console.log(scrollWidth)
    if (value > 0) {
      navRef?.current?.scrollBy(scrollWidth, 0);
    } else if (value < 0) {
      navRef?.current?.scrollBy(-scrollWidth, 0);
    }
  };

  const swipeActions = useSwipeable({
    onSwipedLeft: () => changeTab(1),
    onSwipedRight: () => changeTab(-1),
  });

  return loading ? (
    <div className="Layout">
      <DrawerShimmer data={drawerData} />
      {nav && <Navbar
        data={navBar}
        activeTab={activeTab}
        updateActiveTab={updateActiveTab}
        scrollNav={scrollNav}
        ref={navRef}
      />}
      <div {...swipeActions}>
        <ContentLoader />
      </div>
    </div>
  ) : (
    <div className="Layout">
      {!showStories ? (
        <>
        <Drawer data={drawerData} />
        {nav && <Navbar
          data={navBar}
          activeTab={activeTab}
          updateActiveTab={updateActiveTab}
          scrollNav={scrollNav}
          ref={navRef}
        />}
        <Content
          data={apiData}
          activeTab={activeTab}
          swipeActions={swipeActions}
          callMainApi={getData}
          setNav={setNav}
          setShowStories={setShowStories}
          appLink={appLink}
        />
        </>
      ) : (
        <div className='Stories' style={{position:"relative",height:"100vh",width:"100vw"}}>
          <img src={cross} alt="cross" height="25px" style={{position:"absolute",right:"10px",top:"30px",zIndex:"1000"}} onClick={()=>setShowStories(false)}/>
          <Stories
            stories={[
              "https://whitelabel-assets.s3.ap-south-1.amazonaws.com/production/single/learn/332e22ef-b1de-4712-af72-7f2cd57ba92e.png",
              "https://whitelabel-assets.s3.ap-south-1.amazonaws.com/production/single/learn/27e8bfdc-0c46-4044-a609-10abdbd67da4.png",
              "https://whitelabel-assets.s3.ap-south-1.amazonaws.com/production/single/learn/c3c5e067-175c-4543-ac2d-e392684f0ff7.png",
              "https://whitelabel-assets.s3.ap-south-1.amazonaws.com/production/single/learn/e74c06bc-d32d-416b-8c44-be75de5055b3.png",
              "https://whitelabel-assets.s3.ap-south-1.amazonaws.com/production/single/learn/a68f0082-2071-4a5d-bf09-86455ebb62ef.png",
            ]}
            defaultInterval={1500}
            height={"100%"}
            width={"100%"}
            storyContainerStyles={{
              // border:"1px solid red",
              position:"fixed",
              top:"0",
              left:"0",
              height:"100vh",
              width:"100vw"
            }}
          />
        </div>
        
      )}
      
    </div>
  );
}

export default Layout;
