import React from 'react';
import { useHistory } from 'react-router';

import PointsCard from '../PointsCard';
import LightsImage from "../../../Resources/images/Winter.png";
import homeBanner from '../../../Resources/images/sapno-home.svg'
import rankBanner from '../../../Resources/images/rankbanner.svg'
import winIcon from '../../../Resources/images/winicons.svg'
import PeaknStatsCard from '../PeakAndStatsCard';
import RevenueCard from '../RevenueCard';
import CardFooter from '../CardFooter/CardFooter';

function HomeSlide(props) {
  const { data } = props;
  const history = useHistory()
  const banner = {
    text: "Your Rank & Points in Campaign",
    image: rankBanner,
    gridTemplateColumns: "9fr 11fr",
  };

  const footer = {
    text: data?.activeOrgs,
    button: {
      text: "View leaderboard",
      bgColor: "rgba(255, 255, 255, 1)",
    },
    deepLink: data?.deeplink,
    link : () => history.push('/details/leaderboard')
  };

  const points = [data?.rankCard, data?.pointsCard];

  return (
    <div
      className="Slide"
      style={{
        backgroundColor: '#1E1A3A',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.16)',
        borderRadius: '8px',
        margin: '2px',
      }}
    >
      <PeaknStatsCard data={banner} />
      {/* <RevenueCard data={data?.revenueCard} /> */}
      <PointsCard data={points} />

      <div className='WinSpecial'
        style={{
            width:"92%",
            background:"#322E4E",
            padding: "10px 5px",
            margin:"40px auto 0 auto",
            borderRadius:"4px",
            color:"white",
            fontWeight:"500",
            fontSize:"12px",
            lineHeight:"150%",
            display:"flex",
            alignItems:"center"
        }}
        >
        <img src={winIcon} alt="win" height="30px" style={{marginRight:"10px"}}/>
        Win special prizes for most points & most app downloads during campaign
      </div>
      <CardFooter data={footer} />
    </div>
  );
}

export default HomeSlide;
